import React, { useEffect, useState } from 'react';
import { Table, Tag, Spin, DatePicker, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import './ExcelCalls.css';
import { Link } from 'react-router-dom';
import { getAllCalls, getExcelCalls } from '../../api/call.api';
import { RoutePath } from '../../constants/routePaths';
import Title from 'antd/es/typography/Title';
import { exportCustomerDispositionReport, exportExcelUploadReport } from '../../api/export.api';

const { RangePicker } = DatePicker;

interface CallData {
  id: string;
  key: string;
  timestamp: string;
  duration: number;
  score: string;
  status: 'completed' | 'processing' | 'waiting' | 'failed';
}

const ExcelCalls: React.FC = () => {
  const fileKey = new URL(window.location.href).searchParams.get('fileKey');
  if (!fileKey) {
    window.location.href = RoutePath.HOME;
  }
  const fileName = fileKey.split('/')[1];
  const [data, setData] = useState<CallData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pickerValue, setPickerValue] = useState<[string, string] | null>(null);

  useEffect(() => {
    getExcelCalls({ fileKey }).then((data) => {
      const calls = data.calls.map((call: any) => ({
        id: call._id,
        key: call.key,
        timestamp: new Date(call.timestamp ?? Date.now()).toLocaleString(),
        duration: call.duration,
        score:
          call.callScore && call.totalScore && call.totalScore !== 0
            ? `${Math.round((call.callScore / call.totalScore) * 10000) / 100}%`
            : '-',
        status: call.processStatus,
      }));
      setData(
        calls.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      );
      setIsLoading(false);
    });
  }, []);

  const handleExportCustomerDispositionReport = async () => {
    try {
      setIsDownloading(true);
      const { url } = await exportCustomerDispositionReport(fileKey);
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.click();
    } catch (error) {
      console.error('Error exporting report:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const columns: ColumnsType<CallData> = [
    {
      title: 'Call',
      dataIndex: 'key',
      key: 'key',
      render: (key: string) => <span>{key.split('/')[1]}</span>,
      width: '35%',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: '15%',
    },
    {
      title: 'Call Date & Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: '20%',
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            onChange={(dates, dateStrings) => {
              if (dates) {
                setPickerValue([dateStrings[0], dateStrings[1]]); // Set range
                setSelectedKeys(dateStrings); // Set selected keys
              } else {
                setPickerValue(null); // Clear range
                setSelectedKeys([]);
              }
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="link"
            onClick={() => {
              setPickerValue(null); // Clear picker value
              clearFilters && clearFilters();
              confirm();
            }}
            style={{ marginRight: 8 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
          >
            Filter
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        if (pickerValue) {
          const [startDate, endDate] = pickerValue;
          const recordDate = new Date(record.timestamp).toISOString();
          return (
            recordDate >= new Date(startDate).toISOString() &&
            recordDate <= new Date(endDate).toISOString()
          );
        }
        return false;
      },
    },
    {
      title: 'Processing Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (status: string) => {
        const statusColors: Record<string, string> = {
          completed: 'green',
          processing: 'gold',
          waiting: 'blue',
          failed: 'red',
        };
        return <Tag color={statusColors[status]}>{status}</Tag>;
      },
      filters: [
        { text: 'Completed', value: 'Completed' },
        { text: 'Processing', value: 'Processing' },
        { text: 'Waiting', value: 'Waiting' },
        { text: 'Failed', value: 'Failed' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      render: (id: string, record: CallData) => {
        if (record.status === 'completed') {
          return <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${id}`}>View Call</Link>;
        } else {
          return <span></span>;
        }
      },
    },
  ];

  const handleExportCallsClick = async () => {
    setIsDownloading(true);
    const { url } = await exportExcelUploadReport({ fileKey });
    console.log(url);
    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    link.click();
    setIsDownloading(false);
  };

  return (
    <div className="main-content">
      <Title level={2}>Calls uploaded from Excel</Title>
      <p>{`${fileName.split('@')[0]}.${fileName.split('.')[1]}`}</p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <div></div>
        <div>
          <Button
            // type="primary"
            onClick={handleExportCallsClick}
            style={{ marginLeft: 'auto', marginRight: '10px' }}
            loading={isDownloading}
          >
            Export QA Report
          </Button>
          <Button
            // type="primary"
            onClick={handleExportCustomerDispositionReport}
            style={{ marginLeft: '10px', marginRight: '10px' }}
            loading={isDownloading}
          >
            Export Customer Disposition Report
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Spin size="default" />
      ) : (
        <Table columns={columns} dataSource={data} rowKey="id" />
      )}
    </div>
  );
};

export default ExcelCalls;
