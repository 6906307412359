import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Statistic,
  Progress,
  Table,
  Typography,
  Tag,
  Spin,
  Tabs,
  Space,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import './AgentDetails.css';
import { getAgentDetails } from '../../api/agent.api';
import { RoutePath } from '../../constants/routePaths';
import { Link } from 'react-router-dom';
import { COMPLETE_TEAMS } from '../../config/teamPermissions';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const { Title, Text } = Typography;

interface ParameterScore {
  parameterName: string;
  score: number;
}

interface SectionMetric {
  sectionName: string;
  averageScore: number;
  parameterScores: ParameterScore[];
}

interface Violation {
  text: string;
  score: number;
  sentence: string;
}

interface ViolationType {
  highAlertPhrases?: Violation[];
  negativePhrases?: Violation[];
}

interface NonCompliantCall {
  callId: string;
  violations: ViolationType;
}

interface AgentDetailsProps {
  aId: string;
  overallScore: number;
  sectionMetrics: SectionMetric[];
  callMetrics: {
    totalCalls: number;
    averageCallDuration: number;
    complianceRate: number;
    nonCompliantCalls: NonCompliantCall[];
  };
  calls: Array<{
    _id: string;
    key: string;
    duration: number;
    timestamp: number;
    processStatus: string;
    totalScore: number;
    callScore: number;
    disposition: string;
  }>;
}

const AgentDetails = () => {
  const agentId = new URL(window.location.href).searchParams.get('agentId');
  if (!agentId) {
    window.location.href = RoutePath.HOME;
  }
  const [agentDetails, setAgentDetails] = useState<AgentDetailsProps | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { teamId } = useSelector((state: RootState) => state.persisted.user.value);

  useEffect(() => {
    getAgentDetails({ agentId }).then((res) => {
      setAgentDetails(res);
      setIsLoading(false);
    });
  }, []);

  const parameterColumns: ColumnsType<ParameterScore> = [
    {
      title: 'Parameter',
      dataIndex: 'parameterName',
      key: 'parameterName',
      width: '50%',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: '50%',
      render: (score: number) => (
        <Tag color={score >= 75 ? 'green' : score >= 50 ? 'orange' : 'red'}>
          {score.toFixed(2)}%
        </Tag>
      ),
    },
  ];

  const nonCompliantColumns: ColumnsType<any> = [
    {
      title: 'Violations',
      dataIndex: 'violations',
      key: 'violations',
      width: '85%',
      render: (violations: any[]) => (
        <Space direction="vertical" style={{ width: '100%' }}>
          {violations.map((violation, index) => (
            <div key={index} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Tag color={violation.violationType === 'High Alert' ? 'orange' : 'red'}>
                {violation.violationType}
              </Tag>
              <Text strong>{violation.keyword}</Text>
              <Text type="secondary">({(violation.score * 100).toFixed(2)}%)</Text>
              <Text>{violation.sentence}</Text>
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: '',
      dataIndex: 'callId',
      key: 'action',
      width: '15%',
      render: (callId: string) => (
        <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${callId}`}>View Call</Link>
      ),
    },
  ];

  const transformNonCompliantData = (calls: NonCompliantCall[]) => {
    return calls?.map((call) => {
      const violations = [];

      // Add negative phrases
      call.violations.negativePhrases?.forEach((violation) => {
        violations.push({
          violationType: 'Negative',
          keyword: violation.text,
          score: violation.score,
          sentence: violation.sentence,
        });
      });

      // Add high alert phrases
      call.violations.highAlertPhrases?.forEach((violation) => {
        violations.push({
          violationType: 'High Alert',
          keyword: violation.text,
          score: violation.score,
          sentence: violation.sentence,
        });
      });

      return {
        key: call.callId,
        callId: call.callId,
        violations: violations.sort((a, b) => {
          if (a.violationType === 'Negative' && b.violationType !== 'Negative') return -1;
          if (a.violationType !== 'Negative' && b.violationType === 'Negative') return 1;
          return 0;
        }),
      };
    });
  };

  const getCallColumns = (): ColumnsType<any> => {
    const baseColumns: ColumnsType<any> = [
      {
        title: 'Call',
        dataIndex: 'key',
        key: 'key',
        render: (key: string) => <span>{key.split('/')[1]}</span>,
        width: '30%',
      },
      {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        width: '10%',
        render: (_, record) =>
          record.totalScore
            ? `${Math.round((record.callScore / record.totalScore) * 10000) / 100}%`
            : '-',
        sorter: (a, b) => {
          const scoreA = a.totalScore ? a.callScore / a.totalScore : -1;
          const scoreB = b.totalScore ? b.callScore / b.totalScore : -1;
          return scoreA - scoreB;
        },
      },
    ];

    // Only add disposition column for complete teams
    if (COMPLETE_TEAMS.includes(teamId)) {
      baseColumns.push({
        title: 'Disposition',
        dataIndex: 'disposition',
        key: 'disposition',
        width: '15%',
        render: (disposition: string) => {
          const colorMap = {
            'High PTP': 'green',
            'Low PTP': 'cyan',
            RTP: 'red',
            CALLBACK: 'blue',
            ALREADY_PAID: 'purple',
            DISPUTE: 'orange',
            NRPC: 'volcano',
            NEUTRAL: 'default',
          };

          const displayText: Record<string, string> = {
            HIGH_PTP: 'High PTP',
            LOW_PTP: 'Low PTP',
            RTP: 'RTP',
            CALLBACK: 'Callback',
            ALREADY_PAID: 'Already Paid',
            DISPUTE: 'Dispute',
            NRPC: 'NRPC',
            NEUTRAL: 'Neutral',
          };

          return (
            <Tag color={colorMap[disposition as keyof typeof colorMap]}>
              {displayText[disposition] || disposition}
            </Tag>
          );
        },
      });
    }

    // Add remaining columns
    baseColumns.push(
      {
        title: 'Call Date & Time',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: '20%',
        render: (timestamp: number) => new Date(timestamp).toLocaleString(),
        sorter: (a, b) => a.timestamp - b.timestamp,
      },
      {
        title: 'Processing Status',
        dataIndex: 'processStatus',
        key: 'processStatus',
        width: '15%',
        render: (status: string) => {
          const statusColors: Record<string, string> = {
            completed: 'green',
            processing: 'gold',
            waiting: 'blue',
            failed: 'red',
          };
          return <Tag color={statusColors[status]}>{status}</Tag>;
        },
      },
      {
        dataIndex: '_id',
        key: '_id',
        width: '10%',
        render: (id: string, record: any) => {
          if (record.processStatus === 'completed') {
            return <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${id}`}>View Call</Link>;
          }
          return null;
        },
      }
    );

    return baseColumns;
  };

  const sortedCalls = agentDetails?.calls?.sort((a, b) => b.timestamp - a.timestamp) || [];

  return (
    <div className="agent-details">
      {isLoading ? (
        <Spin size="default" />
      ) : (
        <>
          <Title level={2}>Agent Performance Details</Title>
          <Text type="secondary">Agent ID: {agentDetails?.aId}</Text>

          {/* Key Metrics Row */}
          <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Overall Score"
                  value={agentDetails?.overallScore}
                  suffix="%"
                  precision={2}
                  valueStyle={{ color: agentDetails?.overallScore >= 75 ? '#3f8600' : '#cf1322' }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="Total Calls" value={agentDetails?.callMetrics.totalCalls} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Average Call Duration"
                  value={Math.floor(agentDetails?.callMetrics.averageCallDuration / 60)}
                  suffix={`m ${Math.round(agentDetails?.callMetrics.averageCallDuration % 60)}s`}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Compliance Rate"
                  value={agentDetails?.callMetrics.complianceRate}
                  suffix="%"
                  precision={2}
                  valueStyle={{
                    color: agentDetails?.callMetrics.complianceRate >= 75 ? '#3f8600' : '#cf1322',
                  }}
                />
              </Card>
            </Col>
          </Row>

          {/* Updated Section Metrics using Tabs */}
          <div style={{ marginTop: 24 }}>
            <Card title="Section-wise Performance Metrics">
              <Tabs
                type="card"
                items={agentDetails?.sectionMetrics.map((section) => ({
                  label: (
                    <span>
                      {section.sectionName}{' '}
                      <Tag
                        color={
                          section.averageScore >= 75
                            ? 'green'
                            : section.averageScore >= 50
                              ? 'orange'
                              : 'red'
                        }
                      >
                        {section.averageScore.toFixed(2)}%
                      </Tag>
                    </span>
                  ),
                  key: section.sectionName,
                  children: (
                    <>
                      {/* <Row justify="center" style={{ marginBottom: 24 }}>
                        <Statistic
                          title="Section Score"
                          value={section.averageScore}
                          suffix="%"
                          precision={2}
                          valueStyle={{
                            color:
                              section.averageScore >= 75
                                ? '#3f8600'
                                : section.averageScore >= 50
                                  ? '#faad14'
                                  : '#cf1322',
                            fontSize: '2em',
                          }}
                        />
                      </Row> */}
                      <Table
                        columns={parameterColumns}
                        dataSource={section.parameterScores}
                        pagination={false}
                        size="middle"
                        rowKey="parameterName"
                        style={{ marginTop: 24 }}
                      />
                    </>
                  ),
                }))}
              />
            </Card>
          </div>

          <div style={{ marginTop: 24 }}>
            <Card title="All Calls">
              <Table
                columns={getCallColumns()}
                dataSource={sortedCalls}
                pagination={{ pageSize: 10 }}
                size="middle"
                rowKey="_id"
              />
            </Card>
          </div>

          {/* Non-Compliant Calls Section */}
          <div style={{ marginTop: 24 }}>
            <Card title="Non-Compliant Calls">
              {agentDetails?.callMetrics.nonCompliantCalls?.length ? (
                <Table
                  columns={nonCompliantColumns}
                  dataSource={transformNonCompliantData(
                    agentDetails?.callMetrics.nonCompliantCalls
                  )}
                  pagination={{ pageSize: 10 }}
                  size="middle"
                />
              ) : (
                <div style={{ textAlign: 'center', padding: '24px' }}>
                  <Text type="secondary">
                    No non-compliant calls found. All calls are following compliance guidelines.
                  </Text>
                </div>
              )}
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default AgentDetails;
