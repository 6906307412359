import { callApi } from '.';

export const exportExcelUploadReport = async ({ fileKey }) => {
  const params = new URLSearchParams({ excelKey: fileKey });
  return await callApi({
    method: 'POST',
    url: `/export/excel-upload-report?${params.toString()}`,
    serializerFunc: (response: any) => response.data,
  });
};

export const exportEasilyCollectibleMoneyReport = async () => {
  return await callApi({
    method: 'POST',
    url: `/export/easily-collectible-money-report`,
    serializerFunc: (response: any) => response.data,
  });
};

export const exportFieldCollectionCasesReport = async () => {
  return await callApi({
    method: 'POST',
    url: `/export/field-collection-cases-report`,
    serializerFunc: (response: any) => response.data,
  });
};

export const exportAgentsNeedingTrainingReport = async () => {
  return await callApi({
    method: 'POST',
    url: `/export/agents-needing-training-report`,
    serializerFunc: (response: any) => response.data,
  });
};

export const exportCustomerDispositionReport = async (excelKey: string) => {
  return await callApi({
    method: 'POST',
    url: `/export/customer-disposition-report`,
    requestBody: { excelKey },
    serializerFunc: (response: any) => response.data,
  });
};
