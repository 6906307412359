import { callApi } from '.';

export const getCollectionDashboardMetrics = async (currentMonth: boolean) => {
  return await callApi({
    method: 'GET',
    url: '/dashboard/collection-metrics',
    serializerFunc: (response: any) => response.data,
    params: { currentMonth },
  });
};

export const getAgentDashboardMetrics = async (currentMonth: boolean) => {
  return await callApi({
    method: 'GET',
    url: '/dashboard/agent-metrics',
    serializerFunc: (response: any) => response.data,
    params: { currentMonth },
  });
};

export const getComplianceMetrics = async (currentMonth: boolean) => {
  return await callApi({
    method: 'GET',
    url: '/dashboard/compliance-metrics',
    serializerFunc: (response: any) => response.data,
    params: { currentMonth },
  });
};
